<template>
  <div class="ele-body">

    <el-card shadow="never">
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
        @submit.native.prevent>
        <!-- 搜索表单 -->
        <el-form-item label="省:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
          <el-select v-model="table.where.pid" style="width: 150px ;" @change="handleChangeProv(table.where.pid)"
            placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
          <el-select v-model="table.where.cid" style="width: 150px ;" @change="handleChangeCity(table.where.cid)"
            placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' style="width: 150px ;" @change="$forceUpdate()"
            clearable>
            <el-option v-for="option in districtArr" :value="option.aid" :key="option.aid"
              :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户叫单号码:"
          style="display: flex ; align-items: center; padding-left: 10px; margin-right: 20px;">
          <el-input v-model="table.where.user_phone" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item label="订单号:" style="display: flex ; align-items: center; padding-left: 10px; margin-right: 20px;">
          <el-input v-model="table.where.order_id" placeholder="请输入订单号" clearable />
        </el-form-item>
        <el-form-item label="发生时间：" style="display: flex ; align-items: center; padding-left: 10px; margin-right: 10px;">
          <el-date-picker v-model="value" @change="birthdayChange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="处理状态:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
          <el-select v-model="table.where.status" style="width: 130px ;" @change="$forceUpdate()" placeholder='请选择状态'
            clearable>
            <el-option v-for="option in statusList" :value="option.id" :key="option.id" :label="option.status">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理时间：" style="display: flex ; align-items: center; padding-left: 10px; margin-right: 10px;">
          <el-date-picker v-model="value1" @change="birthdayChange1" type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
            end-placeholder="结束日期" :default-time="['00:00:00']">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
            class="ele-btn-icon">搜索</el-button>
          <el-button @click="reset_search()">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-tables ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
        highlight-current-row :stripe="true" :cell-class-name="nopaddingCell">
        <template slot-scope="{index}">
          <el-table-column type="selection" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="250" align="center" />
          <el-table-column prop="work_order_code" label="工单号" show-overflow-tooltip min-width="150" align="center" />
          <el-table-column prop="cn_work_order_type" label="工单类型" show-overflow-tooltip min-width="150" align="center"/>
          <el-table-column prop="pname" label="省" show-overflow-tooltip min-width="110" align="center" />
          <el-table-column prop="cname" label="市" show-overflow-tooltip min-width="110" align="center" />
          <el-table-column prop="aname" label="县/区" show-overflow-tooltip min-width="110" align="center" />
          <el-table-column prop="create_time" label="发生时间" show-overflow-tooltip min-width="180" align="center" />
          <el-table-column prop="user_phone" label="用户叫单号码" show-overflow-tooltip min-width="110" align="center" />
          <el-table-column prop="audio" label="订单音频" show-overflow-tooltip min-width="120">
            <template slot-scope="{row}">
              <el-link @click="bofang(row)" v-if="row.work_order_title == '模糊电召处理'" icon="el-icon-video-play"
                type="primary" :underline="false">播放录音</el-link>
            </template>
          </el-table-column>
          <el-table-column label="处理状态" show-overflow-tooltip min-width="110">
            <template slot-scope="{row}">
              <div class="status">{{ row.cn_status }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="handle_titme" label="处理时间" show-overflow-tooltip min-width="180" align="center" />
          <el-table-column prop="audio_text" label="音频转文字" show-overflow-tooltip min-width="200" align="center" />
          <el-table-column prop="processing_results" label="处理结果" show-overflow-tooltip min-width="200" align="center" />
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <div v-if="row.status == 1">
                <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false">立即协助下单</el-link>
              </div>
              <div v-else>/</div>
            </template>
          </el-table-column>
        </template>
      </ele-data-tables>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id ? '修改关于我们' : '添加关于我们'" :visible.sync="showEdit" @closed="editForm = {}"
      :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" label-width="82px">

        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit = false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MiddleUtil from '@/config/middles.js';
import EleDataTables from '@/components/EleDataTable/index2.vue'
import {
  mapGetters
} from "vuex";

export default {
  name: "SysNotice",
  components: {
    EleDataTables
  },
  data() {
    return {
      time: 0,
      table: {
        url: '/WorkOrder/getWorkOrderList',
        where: {}
      }, // 表格配置
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editType: '',
      editForm: {
        source: 1,
        status: 1,
        is_top: 2
      }, // 表单数据
      provArr: [],
      cityArr: [],
      districtArr: [],
      statusList: [{
        id: 0,
        status: '待处理'
      },
      {
        id: 2,
        status: '已处理'
      },
      {
        id: 4,
        status: '已失效'
      }
      ],
      player: '',
      value: '',
      value1: '',
    }
  },
  computed: {
    ...mapGetters(["permission"])
  },
  //生命周期 销毁前执行
  beforeDestroy() {
    // 不销毁会在其他页面也执行，浪费性能
    clearInterval(this.time)
    this.time = null
  },

  beforeRouteLeave(to, form, next) {
    next()
    //console.log('离开了页面')
    // this.player.pause();
    this.player = ''
  },
  mounted() {
    let that = this
    MiddleUtil.$on('reload_xiezhu', () => {
      console.log('on11111111111222111111');
      that.$nextTick(() => {
        //that.reset_search()
        that.$refs.table.reload()
      })            
      
    })
    
    this.$http.get('/common/province_list').then(res => {
      var data = JSON.parse(res.data)
      this.provArr = data
    })
    this.timeStart();
  },
  methods: {
    //播放语音
    bofang(row) {
      console.log('row', row);
      if (this.player) {
        this.player.pause();
      }
      this.player = new Audio(row.order_audio);
      // this.player.currentTime = 1;
      this.player.play();
    },
    //筛选发生时间
    birthdayChange() {
      console.log('value', this.value);
      if (this.value != null) {
        this.$set(this.table.where, 'create_time', this.value)
        // this.table.where.start_day = this.tableTime[0]
        // this.table.where.end_day = this.tableTime[1]
      } else {
        this.table.where.create_time = []
      }
    },
    //筛选处理时间
    birthdayChange1() {
      console.log('value1', this.value1);
      if (this.value1 != null) {
        this.$set(this.table.where, 'create_time', this.value1)
        // this.table.where.start_day = this.tableTime[0]
        // this.table.where.end_day = this.tableTime[1]
      } else {
        this.table.where.create_time = []
      }
    },
    timeStart() {
      let that = this;
      this.time = setInterval(() => {
        that.$refs.table.reload()
      }, 20000)


    },
    paction() {
      MiddleUtil.$emit('getOrderInfo', null,0);
    },

    nopaddingCell(val) {
      // console.log(val)
      if (!val.column.label) {
        return "";
      }
      if (val.column.label.indexOf("处理状态") == -1) {
        return ""
      }

      if (!val.row.cn_status) {
        return "";
      }
      let e = val.row.cn_status;

      if (!e) {
        return "nopaddingCell"
      } else if (e.indexOf('已处理') != -1) {
        return 'cellDone'
      } else if (e.indexOf('处理中') != -1) {
        return 'cellProcess'
      } else if (e.indexOf('已取消') != -1 || e.indexOf('已失效') != -1) {
        return 'cellCancel'
      } else if (e.indexOf('待接单') != -1) {
        return 'cellWait'
      }

      return "nopaddingCell";
    },
    //重置
    reset_search() {
      this.value = '';
      this.value1 = '';
      this.table.where = {};
      this.$refs.table.reload();
    },
    edit(row) {
      //console.log('---------row-------',row);
      let order_id = row.order_id;
      MiddleUtil.$emit('getOrderInfo', order_id,0);

    },
    /* 保存编辑 */
    save() {
      this.editForm.notice_type = 2
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/notice/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /***选择省**/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      console.log('uuuuuuuuuuuuu6666666666', e)
      var pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {

        var data = JSON.parse(res.data)
        this.cityArr = data

        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },


    /***选择市**/
    handleChangeCity(e) {
      if (e) {
        /** 获取被选市的cid**/
        var cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          var data = JSON.parse(res.data)
          console.log('ttttttyyyytyty', data);
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },
  }
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
  width: 100%;
}

</style>